import * as React from 'react'
import { classNames } from '~/utils/class-names'

export const styles = {
  base: 'inline-flex px-2 text-sm font-medium rounded-full items-center',
  variant: {
    success: 'text-emerald-700 bg-emerald-50 dark:bg-emerald-700 dark:text-emerald-100',
    danger: 'text-red-700 bg-red-50 dark:text-red-100 dark:bg-red-700',
    warn: 'text-orange-700 bg-orange-50 dark:text-white dark:bg-orange-600',
    neutral: 'text-text-secondary bg-surface-tertiary',
    info: 'text-brand-700 bg-brand-50 dark:text-white dark:bg-brand-600',
  },
}

export type BadgeProps = JSX.IntrinsicElements['span'] & {
  variant?: keyof typeof styles.variant
}

export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(function Badge(props, ref) {
  const { className, variant = 'info', ...rest } = props
  const classes = classNames(styles.base, styles.variant[variant], className)

  return <span className={classes} ref={ref} {...rest} />
})
